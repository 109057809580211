<template>
  <Popup
    class="create-entity"
    :modal-id="modal_id"
    :on-close="onModalClose"
    custom-class="gray-header"
    :toggle-status="toggleStatus"
  >
    <template v-slot:header>
      {{ labels.create_new_user }}
    </template>

    <template v-slot:body>
      <div class="create-entity__body">
        <FormulateInput
          type="text"
          name="text"
          :placeholder="labels.username"
          validation="required"
          error-behavior="blur"
          v-model="userdata.username"
          autocomplete="off"
        />

        <FormulateInput
          type="email"
          name="email"
          :placeholder="labels.email"
          validation="required"
          error-behavior="blur"
          v-model="userdata.email"
          autocomplete="off"
        />

        <FormulateInput
          type="password"
          name="password"
          :placeholder="labels.initial_password"
          validation="required"
          validation-name="Password"
          error-behavior="blur"
          v-model="userdata.password"
          autocomplete="off"
        />

        <FormulateInput
          type="password"
          name="password"
          :placeholder="labels.confirm_password"
          validation="required"
          validation-name="Password"
          error-behavior="blur"
          v-model="userdata.confirm"
          autocomplete="off"
        />

        <FormulateInput
          v-if="$route.params.kind === 'admin'"
          v-model="userdata.role.object.admin_roles"
          :options="roles"
          type="checkbox"
          :label="labels.select_roles_for_user"
        />

        <FormulateInput
          type="select"
          name="select"
          :label="labels.country"
          :placeholder="labels.country"
          :options="countries"
          v-model="userdata.address.country"
        />

        <div id="accordion">
          <div class="card">
            <button
              class="card-header btn btn-link"
              id="headingOne"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ labels.additional_optional_information }}
            </button>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.first_name"
                      error-behavior="blur"
                      v-model="userdata.name.first"
                    />
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.middle_name"
                      error-behavior="blur"
                      v-model="userdata.name.middle"
                    />
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.last_name"
                      error-behavior="blur"
                      v-model="userdata.name.last"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.state"
                      error-behavior="blur"
                      v-model="userdata.address.state"
                    />
                  </div>
                  <div class="col-md-6 col-sm-6 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.city"
                      error-behavior="blur"
                      v-model="userdata.address.city"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.street"
                      error-behavior="blur"
                      v-model="userdata.address.street"
                    />
                  </div>
                  <div class="col-md-4 col-12">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.zip"
                      error-behavior="blur"
                      v-model="userdata.address.zip"
                    />
                  </div>
                </div>
                <div class="phones-container">
                  <div class="pone-numbers" v-if="userdata.phone.length">
                    <div
                      v-for="(num, key) in userdata.phone"
                      :key="key"
                      class="number"
                    >
                      {{ num }}
                    </div>
                  </div>
                  <div class="phone">
                    <FormulateInput
                      type="text"
                      name="text"
                      :placeholder="labels.enter_phone_number"
                      error-behavior="blur"
                      v-model="newPhone"
                    />

                    <Button
                      :label="labels.add"
                      variant="btn-green"
                      font-weight="600"
                      @click="addPhone()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FormulateInput
          type="select"
          name="select"
          :label="labels.default_language"
          :options="options"
          v-model="userdata.defaultLanguage"
        />
      </div>
    </template>

    <template v-slot:footer>
      <div class="create-entity__footer">
        <FormulateInput
          type="submit"
          class="btn-purple"
          :label="labels.create_user"
          form="new-entity"
          @click="registerUser"
        />

        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          font-weight="600"
          :action="onModalClose"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

export default {
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      modal_id: "1",
      toggleStatus: true,
      options: { en: "English", de: "German" },
      newPhone: "",
      userdata: {
        username: "",
        email: "",
        password: "",
        confirm: "",
        userType: "",
        defaultLanguage: "en",
        address: {
          city: "",
          country: "",
          state: "",
          street: "",
          zip: "",
        },
        name: {
          first: "",
          last: "",
          middle: "",
        },
        phone: [],
        role: {
          object: {
            admin_roles: [],
          },
        },
      },
    };
  },
  computed: {
    ...mapState("helper", {
      countries: "countries",
      admin_roles: "admin_roles",
    }),
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
    isAllowed() {
      return this.hasPermissions(["super", "system"]);
    },
    roles() {
      return {
        author: this.labels.author,
        reviewer: this.labels.reviewer,
        web: this.labels.web,
        system: this.labels.system,
        super: this.labels.super,
        creator: this.labels.creator,
        linker: this.labels.linker,
      };
    },
  },
  methods: {
    ...mapActions("helper", {
      getCountries: "getCountries",
    }),
    ...mapActions("user", {
      createUser: "createUser",
      setAdminRole: "setAdminRole",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapMutations("user", ["setUserStoreStateByKey"]),
    addPhone() {
      this.userdata.phone.push(this.newPhone);
      this.newPhone = "";
    },
    onModalClose() {
      if (this.routeBack && Object.keys(this.routeBack).length) {
        this.$router.push(this.routeBack);
      } else {
        this.$router.push({
          name: "users",
          params: { kind: this.$route.params.kind },
        });
      }
    },
    registerUser() {
      this.createUser(this.userdata)
        .then((res) => {
          if (res.success) {
            this.setUserStoreStateByKey({ key: "newUserCreated", value: true });
            this.setAdminRole({
              id: res.data._id,
              role: this.userdata.role.object.admin_roles,
            });
            this.addNotification({
              variant: "success",
              msg: ["user_has_been_registered"],
              labels: this.labels,
              dismissSecs: 5,
            });
            this.onModalClose();
          } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
              dismissSecs: 5,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.userdata.userType =
      this.$route.params.kind.charAt(0).toUpperCase() +
      this.$route.params.kind.substring(1);

    if (!Object.keys(this.countries).length) {
      this.getCountries();
    }
    if (!this.isAllowed && this.admin_roles) {
      this.$router.push({ name: "dashboard" });
    }
  },
  beforeDestroy() {
    let element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
      document.querySelector("body").classList.remove("modal-open");
    }
    this.userdata = {
      username: "",
      email: "",
      password: "",
      confirm: "",
      userType: "",
      defaultLanguage: "en",
      address: {
        country: "",
      },
      role: {
        object: {
          admin_roles: [],
        },
      },
    };
  },
  watch: {
    admin_roles() {
      if (!this.isAllowed) {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-entity {
  &__header-title {
    font-family: "Oswald", sans-serif;
  }

  &__body {
    ::v-deep .formulate-input {
      .formulate-input-wrapper {
        label {
          font-family: "Oswald", sans-serif;
          margin-bottom: 10px;
        }

        .formulate-input-element {
          max-width: 100%;
        }

        .formulate-input-group {
          display: flex;
          .formulate-input-group-item {
            margin-right: 10px;
            margin-bottom: 0;
          }
          label {
            margin-bottom: 0;
            font-family: inherit;
            font-weight: inherit;
          }
        }
      }

      &.horizontal-label-input {
        .formulate-input-wrapper {
          display: flex;
          align-items: center;
          gap: 3rem;

          .formulate-input-element {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    ::v-deep .formulate-input {
      margin-bottom: 0px !important;

      .formulate-input-element {
        button {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
#accordion {
  margin-bottom: 1.5em;
  .card {
    border: 1px solid #cecece;
    .row {
      margin-bottom: 10px;
    }
    .phones-container {
      .phone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        .formulate-input {
          margin-bottom: 0px !important;
          width: 100%;
        }
      }
    }
  }
  button {
    text-align: left;
    border-radius: 0.3em;
    background-color: transparent;
    border: none;
    font-size: 0.9em;
    padding: 0.75rem;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    &:focus,
    &:active {
      box-shadow: none !important;
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
    &[aria-expanded="true"] {
      color: #2c3e50;
      font-weight: 700;
    }
    &[aria-expanded="false"] {
      color: #a8a8a8;
    }
  }
}
</style>
